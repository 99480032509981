import React, { useEffect, useState, useRef } from "react";
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Box,
	Typography,
	Grid,
	Snackbar,
	Alert,
	AlertTitle,
} from "@mui/material";
import UnknownProfilePicture from "../../images/unknownProfilePicture.svg";
import CloseIcon from "../../images/close.svg";
import DummyAvatarImage from "../../images/dummyAvatarImage.png";
import { useNavigate } from "react-router-dom";
import ImageCropper from "../../pages/ImageCropper";
import { useUser } from "../../context/UserContext";
import User from "../../services/user";
import { GenerateAvatarDialog } from "./GenerateAvatarDialog";
import { useAlertStatus } from "../../context/AlertStatusContext";
import ErrorIcon from "../../images/danger.svg";
import SuccessIcon from "../../images/tick-square.svg";

const ChooseProfilePictureDialog = (props: any) => {
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const selfDetails = useUser();
	const navigate = useNavigate();
	const token = localStorage.getItem("sessionId");
	const [file, setFile] = useState<any>("");
	const [result, setResult] = useState("");
	const [open, setOpen] = useState(false);
	const [imagePreviewUrl, setImagePreviewUrl] = useState<any>("");
	const [editImageStatus, setEditImageStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [openAvatarDlg, setOpenAvatarDlg] = useState(false);
	const { updateAlertStatus } = useAlertStatus();
	const [generateAvatarBtn, setGenerateAvatarBtn] = useState(false);

	const fetchPossibleChance = async () => {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/check/available-chances`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			const data = await res.json();
			return data; // Assuming data contains the count
		} catch (err) {
			setAlertMsg("Network Error! Please try again..");
			return 0; // or handle the error accordingly
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const count = await fetchPossibleChance();
			if (count === 0) {
				setGenerateAvatarBtn(true);
			}
		};

		fetchData();
	}, []);

	const handleCloseDialog = () => {
		setOpen(false);
		setImagePreviewUrl(null);
		updateAlertStatus(false,false,"");
	};

	const handleOpenEditor = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];

		if (file && isFileTypeAllowed(file.type)) {
			// Handle the file upload
			setResult(URL.createObjectURL(file));
			setFile(file);
			setOpen(true);

			const newInput = document.createElement("input");
			newInput.type = "file";
			newInput.accept = "image/*";
			newInput.style.display = "none";
			newInput.addEventListener("change", handleOpenEditor);

			const currentInput = fileInputRef.current;
			if (currentInput) {
				const parent = currentInput.parentNode;
				if (parent) {
					parent.replaceChild(newInput, currentInput);
					fileInputRef.current = newInput;
				}
			}
		} else {
			// Display an error message or take appropriate action
			updateAlertStatus(
				true,
				false,
				"Invalid file type, please select image file"
			);
		}
	};

	const isFileTypeAllowed = (fileType: any) => {
		const allowedImageTypes = [
			"image/jpeg",
			"image/png",
			"image/gif",
			"image/bmp",
		];
		return allowedImageTypes.includes(fileType);
	};

	// For upload a photo from local storage
	const profilePhotoUpload = (destFile: any) => {
		setEditImageStatus(true);
		const reader = new FileReader();
		let formData = new FormData();

		formData.append("profilePhoto", destFile);
		formData.append("email", selfDetails.email);
		updateAlertStatus(true, true, "Profile photo upload is in progress.");
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-profile-photo`,
			{
				method: "PUT",
				headers: {
					//   "Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			}
		)
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status >= 400) {
					updateAlertStatus(true, false, "Network Error! Please try again..");
					setEditImageStatus(false);
				} else {
					updateAlertStatus(
						true,
						true,
						"Profile picture uploaded successfully!"
					);
					reader.readAsDataURL(destFile);
					// setImagePreviewUrl(URL.createObjectURL(destFile))
					props.handleImageUrlCallback(URL.createObjectURL(destFile));
					// User.fetchUserInfo(() => {});
					// Your existing code for updating the alert status
				}
			})
			.catch((err) => {
				updateAlertStatus(true, false, "Something went wrong.");
			})
			.finally(() => {
				props.handleClose();
				updateAlertStatus(false, false, "");
			});
		// }
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleGenerateAvatarDialogClose = (status: any, totalcount: any) => {
		setOpenAvatarDlg(false);
		if (status) {
			props.handleClose(false);
			props.handleImageUrlCallback(
				selfDetails?.profilePhotoUrl
					? `${process.env.REACT_APP_PICTURE_URL}${selfDetails?.profilePhotoUrl}`
					: ""
			);
		}
		if (totalcount) {
			setGenerateAvatarBtn(true);
		}
	};

	return (
		<>
			{showAlert && (
				<Snackbar
					sx={{
						"&.MuiSnackbar-anchorOriginTopCenter": {
							top: "70px",
						},
					}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={showAlert}
					autoHideDuration={6000}
					onClose={handleAlertClose}
				>
					<Alert
						onClose={handleAlertClose}
						icon={
							alertStatus ? (
								<img src={SuccessIcon} alt="error-icon" />
							) : (
								<img
									src={ErrorIcon}
									alt="error-icon"
									style={{ marginRight: "10px" }}
								/>
							)
						}
						sx={{
							backgroundColor: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "6px",
							fontSize: "1.125rem",
							fontWeight: "500",
							color: "black",
						}}
					>
						<AlertTitle
							sx={{
								fontSize: "1.125rem",
								fontWeight: "500",
								color: "#000000",
								display: "flex",
								margin: "auto",
								padding: "5px",
								justifyContent: "center !important",
							}}
						>
							{alertMsg}
						</AlertTitle>
					</Alert>
				</Snackbar>
			)}
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				maxWidth="xs"
				fullWidth
				onClose={() => props.handleClose(false)}
				open={props.open}
			>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<IconButton
							aria-label="close dialogbox"
							onClick={() => props.handleClose(false)}
							onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
								event.preventDefault()
							}
							edge="end"
							sx={{ marginLeft: "90%" }}
						>
							<img
								src={CloseIcon}
								alt="close"
							/>
						</IconButton>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							sx={{
								fontSize: "24px !important",
								fontWeight: "500",
								color: "#000",
								fontFamily: "Lexend",
							}}
						>
							Choose Profile Picture
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent sx={{ textAlign: "center", padding: "0 0 48px 0" }}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{imagePreviewUrl ? (
							<img
								src={imagePreviewUrl}
								style={{ width: "50px", height: "50px", borderRadius: "12px" }}
								alt="Unknown Profile Picture"
							/>
						) : (
							<img src={UnknownProfilePicture} alt="Unknown Profile Picture" />
						)}

						<label>
							<Button
								sx={{
									marginLeft: "12px",
									padding: "12px 34px",
									width: "201px",
									height: "40px",
								}}
								component="span"
								variant="contained"
							>
								<Typography
									sx={{ fontSize: "18px", fontWeight: "500", color: "#fff" }}
								>
									Upload Photo
								</Typography>
							</Button>
							<input
								ref={fileInputRef}
								type="file"
								accept="image/*"
								style={{ display: "none" }}
								onChange={(e) => handleOpenEditor(e)}
							/>
						</label>
					</div>
					<Typography
						sx={{ fontSize: "20px", fontWeight: "500", margin: "30px 0" }}
					>
						OR
					</Typography>
					<Grid container direction={"column"}>
						<Grid
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<img src={DummyAvatarImage} alt="Unknown Profile Picture" />
							<Button
								onClick={() =>
									props.requestFrom && props.requestFrom === "signup"
										? setOpenAvatarDlg(true)
										: navigate("/generateAvatar")
								}
								sx={{ marginLeft: "12px", width: "201px", height: "40px" }}
								variant="contained"
								disabled={generateAvatarBtn}
							>
								<Typography
									sx={{ fontSize: "18px", fontWeight: "500", color: "#fff" }}
								>
									Generate Avatar
								</Typography>
							</Button>
						</Grid>
						{generateAvatarBtn && (
							<Grid
								sx={{
									display: "flex",
									justifyContent: "center",
									ml: "70px",
									mt: 1,
								}}
							>
								<Typography
									sx={{
										fontSize: "14px",
										fontWeight: "400",
										lineHeight: "17.5px",
										letterSpacing: "-1%",
										marginLeft: "12px",
										color: "#F57A7A",
									}}
								>
									Come back tomorrow to generate more AI avatars
								</Typography>
							</Grid>
						)}
					</Grid>
				</DialogContent>
			</Dialog>

			{props.permissions ? (
				<ImageCropper
					open={open}
					setOpen={setOpen}
					onClose={handleCloseDialog}
					imagePreviewUrl={result}
					setImagePreviewUrl={setImagePreviewUrl}
					setResult={setFile}
					post={false}
					photoUpload={profilePhotoUpload}
					cover={false}
					editImageStatus={props.editImageStatus}
					homeSidebar={true}
				/>
			) : (
				<ImageCropper
					open={open}
					setOpen={setOpen}
					onClose={handleCloseDialog}
					imagePreviewUrl={result}
					setImagePreviewUrl={setImagePreviewUrl}
					setResult={setFile}
					post={false}
					photoUpload={profilePhotoUpload}
					cover={false}
				/>
			)}

			{openAvatarDlg && (
				<GenerateAvatarDialog
					handleGenerateAvatarDialogClose={handleGenerateAvatarDialogClose}
					handleAlertClose={handleAlertClose}
				/>
			)}
		</>
	);
};
export default ChooseProfilePictureDialog;
