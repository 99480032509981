import {
	Box,
	Button,
	Card,
	CardMedia,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import Group from "../../images/Group.svg";
import { useNavigate, useParams } from "react-router-dom";
import Like from "../../images/like.svg";
import calendarIcon from "../../images/calendar.svg";
import Location from "../../images/location.svg";
import ExportIcon from "../../images/export.svg";
import { useEffect, useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import Venue from "./Venue";
import EditIcon from "../../images/edit-white.svg";
import DeleteIcon from "../../images/trash-orange.svg";
import NewEvent from "./NewEvent";
import { useUser } from "../../context/UserContext";
import { useAlertStatus } from "../../context/AlertStatusContext";
import SimilarArtists from "../Social/similarArtists";
import { EventsSidePanel } from "../commonComponents/EventsSidePanel";
import CloseIcon from "../../images/close.svg";
import ShareIcon from "../../images/share1.svg";
import CreatePostNew from "../Posts/CreatePostNew";
import { format } from "date-fns";
import { getGenre } from "../../pages/SocialProfileSetting";
import  genreIcons from "../../components/Gigs/ViewGig";
//Genre icons
import Americana from "../../icons/Genres/Color/Color_Americana.svg";
import AvantGarde from "../../icons/Genres/Color/Color_Avante-Garde.svg";
import Baroque from "../../icons/Genres/Color/Color_Baroque.svg";
import BigBand from "../../icons/Genres/Color/Color_Big Band.svg";
import Bluegrass from "../../icons/Genres/Color/Color_Bluegrass.svg";
import Blues from "../../icons/Genres/Color/Color_Blues.svg";
import Bollywood from "../../icons/Genres/Color/Color_Bollywood.svg";
import BroadwayShowtunes from "../../icons/Genres/Color/Color_Broadway-Showtunes.svg";
import ChamberMusic from "../../icons/Genres/Color/Color_Chamber Music.svg";
import ClassicRock from "../../icons/Genres/Color/Color_Classic Rock.svg";
import Classical from "../../icons/Genres/Color/Color_Classical.svg";
import ContemporaryClassical from "../../icons/Genres/Color/Color_Contemporary Classical.svg";
import Country from "../../icons/Genres/Color/Color_Country.svg";
import Disco from "../../icons/Genres/Color/Color_Disco.svg";
import Drumline from "../../icons/Genres/Color/Color_Drumline.svg";
import EDM from "../../icons/Genres/Color/Color_EDM.svg";
import ElectronicDance from "../../icons/Genres/Color/Color_Electronic-Dance.svg";
import Folk from "../../images/genres/folk.svg"; 
import Funk from "../../icons/Genres/Color/Color_Funk.svg";
import Genere from "../../images/genres/Genere.png";
import Gospel from "../../icons/Genres/Color/Color_Gospel.svg";
import HiphopRap from "../../icons/Genres/Color/Color_HipHop-Rap.svg";
import House from "../../icons/Genres/Color/Color_House.svg";
import IndieAlternative from "../../icons/Genres/Color/Color_Indie-Alt.svg";
import Jazz from "../../icons/Genres/Color/Color_Jazz.svg";
import KPop from "../../icons/Genres/Color/Color_KPop.svg";
import Latin from "../../icons/Genres/Color/Color_Latin.svg";
import Metal from "../../icons/Genres/Color/Color_Metal.svg";
import Minimalism from "../../icons/Genres/Color/Color_Minimalism.svg";
import NewAge from "../../icons/Genres/Color/Color_New Age.svg";
import Opera from "../../icons/Genres/Color/Color_Opera.svg";
import Orchestral from "../../icons/Genres/Color/Color_Orchestral.svg";
import Pop from "../../icons/Genres/Color/Color_Pop.svg";
import Punk from "../../icons/Genres/Color/Color_Punk.svg";
import RB from "../../icons/Genres/Color/Color_R&B.svg";
import Ragtime from "../../icons/Genres/Color/Color_Ragtime.svg";
import Reggae from "../../icons/Genres/Color/Color_Reggae.svg";
import Reggaeton from "../../icons/Genres/Color/Color_Reggaeton.svg";
import Religious from "../../icons/Genres/Color/Color_Religious.svg";
import Rock from "../../icons/Genres/Color/Color_Rock.svg";
import Romantic from "../../icons/Genres/Color/Color_Romantic.svg";
import Salsa from "../../icons/Genres/Color/Color_Salsa.svg";
import Ska from "../../icons/Genres/Color/Color_Ska-18.svg";
import Soul from "../../icons/Genres/Color/Color_Soul.svg";
import Soundtrack from "../../icons/Genres/Color/Color_Soundtrack.svg";
import Swing from "../../icons/Genres/Color/Color_Swing.svg";
import Symphonic from "../../icons/Genres/Color/Color_Symphonic.svg";
import Techno from "../../icons/Genres/Color/Color_Techno.svg";
import WindEnsemble from "../../icons/Genres/Color/Color_Wind Ensemble.svg";


const dummyEventDetail = {
	id: "2",
	type: "EVENT",
	title: "test event",
	description:
		"Live event by Taylor Swift. Playing her latest albums along with her band. Live event by Taylor Swift. Playing her latest albums along with her bandLive event by Taylor Swift. Playing her latest albums along with her bandLive event by Taylor Swift. Playing her latest albums along with her band",
	cost: "10$",
	cityCountry: "Pune, Maharashtra, India",
	venue: "",
	startDateTime: "2023-09-20 07:30:14",
	endDateTime: "2023-09-20 08:30:14",
	timezone: "Africa/Abidjan",
	duration: "30",
	requirements: "",
	instruments: "",
	thumbnailUrl: "/nwusr7/8e11182872cb48d7",
	bannerUrl: "",
	eventTicketLink: "",
	likesCount: 0,
	userProfile: {
		user: {
			id: 157,
		},
	},
};
type RouteParams = {
	eventId: string;
};
const ViewEvent = (props: any) => {
	const user = useUser();
	const { updateAlertStatus } = useAlertStatus();
	const navigate = useNavigate();

	const token = localStorage.getItem("sessionId");

	const param = useParams<RouteParams>();

	const [likeCount, setLikeCount] = useState<any>();
	const [liked, setLiked] = useState(false);
	const [eventDetail, setEventDetails] = useState<any>("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [location, setLocation] = useState({ lat: 0, lng: 0 });
	const [openEditEventPopup, setOpenEditEventPopup] = useState(false);
	const [eventLocation, setEventLocation] = useState("");
	const [openDeleteEventPopup, setOpenDeleteEventPopup] = useState(false);
	const [loading, setLoading] = useState(true);
	const [genresValue, setGenresValue] = useState([]);
	const [liveShow, setLiveShow] = useState(false);
	const [sharePost, setSharePost] = useState(false);


	function getLocationLatLng(
		location: string
	): Promise<{ lat: number; lng: number }> {
		return fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
				location
			)}&key=${process.env.REACT_APP_GOOOGLE_MAPS_API_KEY}`
		)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(
						`Geocoding API request failed with status: ${response.status}`
					);
				}
			})
			.then((data) => {
				if (data.status === "OK") {
					const { lat, lng } = data.results[0].geometry.location;
					return { lat, lng };
				} else {
					throw new Error("Geocoding API request failed");
				}
			})
			.catch((error) => {
				console.error("Error during geocoding request:", error);
				throw error;
			});
	}

	const fetchEventDetails = () => {
		setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/get-event/details`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				id: param.eventId,
			}),
		})
			.then((res) => {
				if (res.status !== 200) {
				} else {
					return res.json();
				}
			})
			.then(async (data) => {
				const currentTime = new Date();
				const start = new Date(data.startDateTime);
				const end = new Date(data.endDateTime);
				if (currentTime >= start && currentTime <= end) {
					setLiveShow(true);
				} else {
					setLiveShow(false);
				}
				setLikeCount(data.likesCount);
				setEventDetails(data);
				const String = `${data.venue} ${data.cityCountry}`;
				const concatenatedString = String.replace(/#/g, '');
				setEventLocation(concatenatedString);
				setLiked(data.userReaction);
				setLoading(false);
				const prevGenres = data.genres;
				const resultArray = data.genres
					? prevGenres.split(",").map((item: any) => ({ title: item.trim() }))
					: [];
				setGenresValue(resultArray);
				await getLocationLatLng(concatenatedString).then((result) => {
					setLocation({ lat: result.lat, lng: result.lng });
				});
			})
			.catch((err) => {
				//   setLoading(false);
				setLoading(false);
			});
	};

	const handleLike = (status: any) => {

		let shouldResetTimeout = true;

		const timeoutId = setTimeout(() => {
			if (shouldResetTimeout) {
				setLikeCount(eventDetail.likesCount);
				setLiked(eventDetail.userReaction);
			}
		}, 2000);

		fetch(`${process.env.REACT_APP_BASE_URL}/api/events-reaction/react`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				events: {
					id: param.eventId,
				},
			}),
		})
			.then((res) => {

				clearTimeout(timeoutId);
				shouldResetTimeout = false;

				if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})

			.then((result) => {

				// Update like count directlly from the API response
				setLikeCount(result.likesCount);
				setLiked(result.isReacted);

		})

			.catch((err) => {

				clearTimeout(timeoutId);
				shouldResetTimeout = false;

				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	useEffect(() => {
		fetchEventDetails();
	}, []);

	const handleSuccess = () => {
		fetchEventDetails();
		setOpenEditEventPopup(false);
		props.onSuccess();
	};
	const handleOnClose = () => {
		setOpenEditEventPopup(false);
	};

	const handleDelete = () => {
		updateAlertStatus(true, true, "Deleting Event");
		// const eventsDTO = { id: param.eventId };

		fetch(`${process.env.REACT_APP_BASE_URL}/api/events/delete-event`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(eventDetail),
		})
			.then((response) => {
				if(response.status === 502){
					navigate('/defaultPage')
				} else if (response.status >= 400) {
					ErrorAlert(
						response.status,
						setAlertStatus,
						setShowAlert,
						setAlertMsg
					);
				} else {
					navigate("/events");
				}
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				//setAlertMsg("Network Error! Please try again..");
			});
	};

	const handleCloseDeletePopup = () => {
		setOpenDeleteEventPopup(false);
	};

	const utcTime: string = eventDetail.startDateTime;
	const date = new Date(utcTime);

	// Format and display using the browser's time zone
	const formattedDate = date.toLocaleString('en-US', {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});

	const finalFormattedDate = `${formattedDate.split(',')[0]}, ${formattedDate.split(',')[1]} - ${formattedDate.split(',')[2]}`;


	const handleTicket = () => {
		if (eventDetail.ticketLink) {
			window.open(eventDetail.ticketLink, "_blank");
		}
	};

	const handleShare = () => {
		setSharePost(true);
	};
	const handlePostClose = (call: boolean) => {
		if (call) {
			navigate("/home");
		}
		setSharePost(false);
	};
	const genreIcons: { [key: string]: any } = {
		"Americana": Americana,
		"Avant-garde":AvantGarde,
		"Baroque":Baroque,
		"Big Band":BigBand,
		"Bluegrass":Bluegrass,
		"Blues":Blues,
		"Bollywood":Bollywood,
		"Broadway/Showtunes":BroadwayShowtunes,
		"Chamber-Music":ChamberMusic,
		"Classic Rock":ClassicRock,
		"Classical":Classical,
		"Contemporary Classical":ContemporaryClassical,
		"Country":Country,
		"Disco"	:Disco,
		"Drumline":Drumline,
		"EDM":EDM,
		"Electronic/Dance":ElectronicDance,
		"Folk":Folk,
		"Funk":Funk,
		"Gospel":Gospel,
		"Hip-hop/Rap":HiphopRap,
		"House":House,
		"Indie/Alternative":IndieAlternative,
		"Jazz":Jazz,
		"K-Pop":KPop,
		"Latin":Latin,
		"Metal":Metal,
		"Minimalism":Minimalism,
		"New Age":NewAge,
		"Opera":Opera,
		"Orchestral":Orchestral,
		"Pop":Pop,
		"Punk":Punk,
		"R&B":RB,
		"Ragtime":Ragtime,
		"Reggae":Reggae,
		"Reggaeton":Reggaeton,
		"Religious":Religious,
		"Rock":Rock,
		"Romantic":Romantic,
		"Salsa":Salsa,
		"Ska":Ska,
		"Soul":Soul,
		"Soundtrack":Soundtrack,
		"Swing":Swing,
		"Symphonic":Symphonic,
		"Techno":Techno,
		"Wind Ensemble":WindEnsemble
	  };

	return (
		<>
			<Grid className="center-align mainPanel" sx={{ margin: "auto" }}>
				<ErrorAlertDisplay
					alertMsg={alertMsg}
					alertStatus={alertStatus}
					showAlert={showAlert}
					handleAlertClose={handleAlertClose}
				/>
				{loading ? (
					<Grid container xs={12} justifyContent="center" sx={{ p: "16px" }}>
						<CircularProgress /> {/* The loading spinner */}
						<Grid container item justifyContent="center">
							Searching ...
						</Grid>
					</Grid>
				) : (
					<Grid
						container={true}
						className="column mainPanel"
						rowSpacing={{ xs: 1 }}
					>
						<Grid
							item={true}
							className="mainPanel"
							sx={{ zIndex: 99, width: "100%" }}
						>
							<Grid item={true} xs={12} mt={3}>
								<Grid container={true} columns={{ xs: 12 }}>
									<Grid item xs={6}>
										<Button
											variant="text"
											startIcon={
												<img src={Group} alt="Back to previous page" />
											}
											onClick={() => {
												window.history.back();
											}}
										>
											<Typography
												sx={{
													fontStyle: "normal",
													fontWeight: "500",
													fontSize: "36px !important",
													lineHeight: "normal",
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis",
													maxWidth: "50rem",
												}}
											>
												{eventDetail.title}
												{/* (id={param.eventId}) */}
											</Typography>
										</Button>

										{liveShow && (
											<Chip
												label={"Live Event"}
												style={{
													marginLeft: "10px",
													color: "white",
													backgroundColor: "#196AEB",
												}}
											/>
										)}
									</Grid>
									<Grid item xs={6} style={{ textAlign: "end" }}>
										{user?.userProfileId === eventDetail.userProfile?.id &&
											user.userAccountStatus !== "SUSPEND" && (
												<>
													<Button
														sx={{
															borderRadius: "24px",
															border: "2px solid #FF702A",
															backgroundColor: "#FFFFFF",
															color: "#FF702A",
															marginRight: "8px",
															width: "139px",
															height: " 40px",
															padding: "8px, 21.5px, 8px, 21.5px",
															justifyContent: "center",
															fontFamily: "Lexend",
															fontSize: "1.125rem",
															fontStyle: "normal",
															fontWeight: 500,
														}}
														size="large"
														startIcon={<img src={DeleteIcon} alt="Export" />}
														variant="outlined"
														onClick={() => {
															setOpenDeleteEventPopup(true);
														}}
													>
														Delete
													</Button>
													<Button
														sx={{
															borderRadius: "24px",
															backgroundColor: "#FF702A",
															color: "#FFFFFF",
															width: "132px",
															height: " 40px",
															padding: "8px, 30.5px, 8px, 29.5px",
															justifyContent: "center",
															fontFamily: "Lexend",
															fontSize: "1.125rem",
															fontStyle: "normal",
															fontWeight: 500,
														}}
														disabled={user.userAccountStatus === "SUSPEND"}
														size="large"
														startIcon={<img src={EditIcon} alt="Export" />}
														variant="contained"
														onClick={() => setOpenEditEventPopup(true)}
													>
														Edit
													</Button>
												</>
											)}
									</Grid>
								</Grid>
							</Grid>
							{/* </Grid> */}
							<Grid item={true} xs={12} mt={3}>
								<Grid container={true} columns={{ xs: 12 }}>
									<Grid item xs={9} className="centerPanelwoRightEvent">
										<Grid item>
											<CardMedia
												component="img"
												image={
													eventDetail.bannerUrl !== null && eventDetail.bannerUrl !== ""
														? `${process.env.REACT_APP_PICTURE_URL}${eventDetail.bannerUrl}`
														: `${process.env.REACT_APP_PICTURE_URL}/defaultimages/event-banner.png`
												}
												sx={{
													height: "100%",
													width: "100%",
													border: (eventDetail.bannerUrl !== null && eventDetail.bannerUrl !== "") ? "none" : "2px solid #999999",

													borderRadius: "10px",
													objectFit: "fill",
													// width: "912px",
													// height: "230px",
												}}
											/>
										</Grid>
										{/* Event Details */}
										<Card
											sx={{
												// width: "912px",
												// height: "270px",
												borderRadius: "12px",
												marginTop: "30px",
												padding: "16px 22px 30px 62px",
											}}
										>
											<Grid
												container
												sx={{ textAlign: "right", padding: "5px" }}
												direction={"row"}
												justifyContent={"flex-end"}
												alignItems={"center"}
												spacing={2}
											>
												{user?.userProfileId ===
													eventDetail.userProfile?.id && (
													<Grid item={true}>
														<IconButton
															color="primary"
															size="small"
															onClick={handleShare}
															disabled={user.userAccountStatus === "SUSPEND"}
														>
															<img
																src={ShareIcon}
																alt="Share"
																style={{ height: "24px", width: "24px" }}
															/>
														</IconButton>
														<Typography
															sx={{
																fontFamily: "Lexend",
																fontStyle: "normal",
																fontWeight: "500",
																fontSize: "14px !important",
																lineHeight: "20px",
																display: "contents",
																marginRight: "2rem",
															}}
														>
															Share
														</Typography>
													</Grid>
												)}
												<Grid item={true}>
												{liked ? (
													<IconButton
														color="primary"
														size="small"
														onClick={() => {
															handleLike(true);
														}}
														disabled={user.userAccountStatus === "SUSPEND"}
													>
														<ThumbUpIcon
															sx={{
																color: "#3F77D7",
																height: "24px",
																width: "24px",
															}}
														/>
													</IconButton>
												) : (
													<IconButton
														color="primary"
														size="small"
														onClick={() => {
															handleLike(false);
														}}
														disabled={user.userAccountStatus === "SUSPEND"}
													>
														<img
															src={Like}
															alt="Like"
															style={{ height: "24px", width: "24px" }}
														/>
													</IconButton>
												)}
												<Typography
													sx={{
														fontFamily: "Lexend",
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "14px !important",
														lineHeight: "20px",
														display: "contents",
													}}
												>
													{likeCount} {likeCount<=1 ? 'Like':'Likes'}
												</Typography>
												</Grid>
											</Grid>

											<Grid item>
												<IconButton color="primary" size="small">
													<img
														src={calendarIcon}
														alt="Date"
														style={{ height: "24px", width: "24px" }}
													/>
												</IconButton>
												<Typography
													sx={{
														fontFamily: "Lexend",
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "18px !important",
														lineHeight: "22.5px",
														display: "contents",
													}}
												>
													{finalFormattedDate}
												</Typography>
											</Grid>

											<Grid container item xs={12} >
                                            <Grid item xs={0.5}>
												<IconButton color="primary" size="small">
													<img
														src={Location}
														alt="Location"
														style={{ height: "24px", width: "24px" }}
													/>
												</IconButton>
												</Grid>
                                            <Grid item xs={11.5}>
												<Typography
													sx={{
														fontFamily: "Lexend",
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "14px !important",
														lineHeight: "20px",
														display: "contents",
													}}
												>
													{eventLocation}
												</Typography>
												</Grid>
											</Grid>
											<Grid item sx={{ marginTop: "10px", paddingLeft: "5px" }}>
												<Typography
													sx={{
														fontFamily: "Lexend",
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "24px !important",
														lineHeight: "30px",
													}}
												>
													Event Details
												</Typography>
												<Typography
													sx={{
														fontFamily: "Lexend",
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "16px !important",
														lineHeight: "20px",
														color: "#5B5B65",
													}}
												>
													{eventDetail.description !== null &&
													eventDetail.description !== "null"
														? eventDetail.description
														: ""}
												</Typography>
											</Grid>
											<Grid
												item
												xs={12}
												sx={{
													marginTop: "10px",
													paddingLeft: "5px",
													display: "flex",
												}}
											>
												<Typography
													sx={{
														fontFamily: "Lexend",
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "24px !important",
														lineHeight: "30px",
													}}
												>
													Genre:
												</Typography>
												<Typography
													sx={{
														fontFamily: "Lexend",
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "16px !important",
														lineHeight: "20px",
														color: "#5B5B65",
														ml: "0.5rem",
														maxWidth: "30rem",
													}}
												>
													<Stack
														direction="row"
														spacing={1}
														sx={{ flexWrap: "wrap" }}
														rowGap={1}
													>
														{genresValue.map((genre: any, index) => (
															<Chip
    													key={index}
  														label={
      													  <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
           													 <img style={{ height: "24px", width: "24px" }} src={genreIcons[genre.title]} alt={genre.title} />
            												<span>{genre.title}</span>
        												  </div>
    }
/>														))}
													</Stack>
												</Typography>
											</Grid>
										</Card>

										<Card
											sx={{
												// width: "912px",
												// height: "463px",
												borderRadius: "12px",
												marginTop: "30px",
												padding: "16px 22px 30px 62px",
											}}
										>
											<Grid
												container={true}
												columns={{ xs: 12 }}
												sx={{ padding: "6px" }}
											>
												<Grid container={true} xs={6}>
													<Typography
														sx={{
															fontFamily: "Lexend",
															fontStyle: "normal",
															fontWeight: "500",
															fontSize: "24px !important",
															lineHeight: "30px",
															paddingBottom: "10px",
														}}
													>
														Location
													</Typography>

													<Card sx={{ width: "402px", height: "357px" }}>
														<Venue
															lat={location.lat}
															lng={location.lng}
															location={location}
														/>
													</Card>
												</Grid>
												<Grid container={true} xs={6}>
													{/* <Grid item={true} > */}

													{/* </Grid> */}
													<Grid
														item={true}
														xs={12}
														sx={{ marginLeft: { md: "4rem", lg: "7rem" } }}
													>
														{user?.userProfileId !==
															eventDetail.userProfile?.id && (
															<>
																{eventDetail.ticketLink !== null &&
																eventDetail.ticketLink !== "null" &&
																eventDetail.ticketLink !== "" ? (
																	<Typography
																		sx={{
																			borderRadius: "24px",
																			// backgroundColor: "customColor.orange",
																			color: "secondary",
																			// "&:hover": {
																			// 	backgroundColor: "customColor.orange",
																			// 	color: "secondary",
																			// },
																			// background:
																			// 	eventDetail.ticketLink && eventDetail.ticketLink !== 'null' ? "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)" : '#C2C2C3',
																			// width: "239px",
																			// height: " 48px",
																			// padding: "12px 12px 12px 64px",
																			justifyContent: "center",
																			alignItems: "flex-start",
																			textAlign: "left",
																			fontFamily: "Lexend",
																			fontStyle: "normal",
																			fontWeight: "500",
																			fontSize: "24px !important",
																			lineHeight: "30px",
																		}}
																	>
																		Buy Ticket
																	</Typography>
																) : (
																	""
																)}
															</>
														)}
														<Typography
															sx={{
																fontFamily: "Lexend",
																fontStyle: "normal",
																fontWeight: "500",
																fontSize: "16px !important",
																lineHeight: "20px",
																color: "5B5B65",
																mt: "30px",
															}}
														>
															Ticket Price ${eventDetail.cost}
														</Typography>
														<>
															{user?.userProfileId !==
																eventDetail.userProfile?.id && (
																<>
																	{eventDetail.ticketLink !== null &&
																	eventDetail.ticketLink !== "null" &&
																	eventDetail.ticketLink !== "" ? (
																		<Button
																			sx={{
																				borderRadius: "24px",
																				// backgroundColor: "customColor.orange",
																				color: "secondary",
																				"&:hover": {
																					backgroundColor: "customColor.orange",
																					color: "secondary",
																				},
																				background:
																					eventDetail.ticketLink !== null &&
																					eventDetail.ticketLink !== "null" && user.userAccountStatus !== "SUSPEND"
																						? "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)"
																						: "#C2C2C3",
																				width: "239px",
																				height: " 48px",
																				padding: "12px 12px 12px 64px",
																				alignItems: "flex-start",
																				textAlign: "left",
																				fontFamily: "Lexend",
																				fontSize: "1.125rem",
																				fontStyle: "normal",
																				fontWeight: 500,
																				lineHeight: "normal",
																				letterSpacing: "0.9px",
																				justifyContent: "center",
																				mt: "30px",
																			}}
																			size="large"
																			endIcon={
																				<img src={ExportIcon} alt="Export" />
																			}
																			variant="contained"
																			disabled={
																				eventDetail.ticketLink !== null &&
																				eventDetail.ticketLink !== "null" &&
																				user.userAccountStatus !==
																					  "SUSPEND"
																					? false
																					: true
																			}
																			className="submit-button"
																			onClick={() => handleTicket()}
																		>
																			<Typography>Buy Ticket</Typography>
																		</Button>
																	) : (
																		<></>
																	)}
																</>
															)}
														</>
													</Grid>
												</Grid>
											</Grid>
										</Card>
									</Grid>
									<Grid item xs={2.6} className="rightPanelWidthEvent">
										<Card
											sx={{
												width: "240px",
												minHeight: "200px",
												padding: "10px 24px",
												textAlign: "center",
											}}
										>
											<Typography
												variant="body1"
												sx={{
													fontSize: "24px",
													fontWeight: "500",
													color: "#000",
												}}
											>
												Similar Events
											</Typography>
											<EventsSidePanel type={"EVENT"} />
										</Card>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>

			{openEditEventPopup && (
				<NewEvent
					open={openEditEventPopup}
					onSuccess={handleSuccess}
					onPostEventClose={handleOnClose}
					eventType={"modifyEvent"}
					eventDetails={eventDetail}
					setAlertMsgMain={setAlertMsg}
					setShowAlertMain={setShowAlert}
				    setAlertStatusMain={setAlertStatus}
				/>
			)}

			{openDeleteEventPopup && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteEventPopup}
					onClose={() => handleCloseDeletePopup()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeletePopup()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{
									marginLeft: "90%",
									position: "absolute",
									top: "8px",
								}}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Delete "{eventDetail.title}"</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={() => handleCloseDeletePopup()}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => handleDelete()}>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{sharePost && (
				<CreatePostNew
					open={sharePost}
					eventPostDto={eventDetail}
					postType={"EVENT"}
					editPost={false}
					location={"details"}
					handleClose={(call: boolean) => {
						handlePostClose(call);
					}}
				/>
			)}
		</>
	);
};

export default ViewEvent;
